.permutation {
  --permutation-size: 50rem;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: darken;

  /*animation: move 1s infinite linear;*/
}

.grecaptcha-badge {
  visibility: visible;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: var(--permutation-size) 0;
  }
}

.inputFields {
  @apply w-full block text-black border-white rounded-md shadow-sm sm:focus:ring-berry focus:border-berry;

  &::placeholder {
    @apply text-grey-500;
  }
}

.radioButtons {
  @apply w-4 h-4 border-white focus:ring-white text-berry;
}

.submitButton {
  @apply border-berry focus:border-black ml-5 mb-6 bg-berry;

  &:after {
    @apply bg-white;
  }

  &:hover,
  &:active {
    @apply text-black border-white bg-white;
  }

  &:global(.loading) {
    @apply bg-white cursor-not-allowed animate-pulse;
  }
}

.modalButton {
  @apply border-black focus:border-black;
}

.modalContent {
  @apply fixed top-1/2 left-1/2 inline-block;
  @apply px-10 py-12 text-left text-white;
  @apply border border-black rounded-lg;
  transform: translate(-50%, -50%);
  min-width: calc(100vw - 6rem);

  p {
    @apply mb-8;
  }

  @screen sm {
    min-width: auto;
  }
}

.successModalOverlay {
  @apply fixed inset-0 bg-gray-600 opacity-75;
}

.successModalContent {
  @apply bg-berry;
}

.errorModalOverlay {
  @apply fixed inset-0 bg-gray-600 opacity-75;
}

.errorModalContent {
  @apply bg-berry;
}

.iconButton {
  @apply w-4 h-4 inline-flex items-center justify-center;
  @apply text-white absolute cursor-pointer;
  border-radius: 100%;
  top: 1rem;
  right: 1rem;
}

.vector {
  @apply flex absolute -z-10;
  @apply -right-4 bottom-4;
}

.contactWrapper {
  @apply container my-16 lg:my-32;
  @apply flex flex-wrap justify-center gap-4;

  .introWrapper {
    @apply relative flex flex-col mt-10;
    @apply max-w-md sm:max-w-lg mx-auto lg:mx-0;

    .title {
      @apply text-4xl sm:text-7xl;
      @apply font-semibold tracking-tight;
    }

    .copy {
      @apply text-white mt-4 sm:mt-3;
    }

    .imageWrapper {
      @apply relative w-full flex flex-wrap md:flex-nowrap  items-end;
      @apply text-white px-4 py-12 mt-16;

      .contactImage {
        @apply rounded-3xl;
        @apply h-full;
      }

      .imageBG {
        @apply absolute inset-0;
        svg {
          @apply w-full h-full;
        }
      }
    }
  }
}

.formWrapper {
  @apply relative pr-4 sm:px-6 lg:px-8 lg:pr-8;
  @apply lg:max-w-7xl;
  @apply max-w-md mx-auto sm:max-w-lg lg:mx-0;
}
