.factsHeader {
  @apply w-1/2 mb-20;

  & h2 {
    @apply mb-4;
  }
}

.factHeadlineWrapper {
  @apply grid grid-cols-1 grid-rows-1;
}

.factsList {
  @apply grid md:grid-cols-3 gap-24;
  list-style-type: none;

  & h3 {
    @screen md {
      font-size: 1.54rem;
    }

    @screen lg {
      font-size: 2.16rem;
    }

    @screen xl {
      font-size: 2.5rem;
    }
  }
}

.factHeadline {
  @apply row-start-1 col-start-1 flex items-end mb-4;
}

.factHeadlineGraphic {
  @apply flex row-start-1 col-start-1 rounded-3xl w-48 h-48 -ml-8;
}

.text {
  @apply mt-4;

  > p:last-child {
    @apply mb-0;
  }
}
