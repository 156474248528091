.linkListItemWrapper {
  @apply flex mb-16 relative items-start;
}

.link {
  @apply relative text-4xl;
}

.bg {
  @apply absolute rounded-lg top-0 left-0 -ml-4 -mt-1;
  inline-size: 2rem;
  block-size: 2rem;
}

.icon {
  @apply ml-2;
  vertical-align: baseline;
  display: inline;
}
