.sectionWrapper {
  @apply relative bg-black;
}

.containerStart {
  @apply container flex justify-between items-center mb-4;
}

.navButton {
  @apply flex hidden justify-end sm:block;
}

.headline {
  @apply mb-0 text-white;
}

.prevButton,
.nextButton {
  @apply m-2 text-white border-white;

  &.highlight {
    &:after {
      background: theme("colors.white.DEFAULT");
      transform: translate3d(0, 100%, 0);
      transition: all 0.3s;
    }

    &:hover,
    &:active {
      border-color: theme("colors.white.DEFAULT");
      color: theme("colors.black.DEFAULT");

      &:after {
        transform: translate3d(0, 0, 0) skewY(15deg) scale(1, 3);
      }
    }
  }
}

.contentWrapper {
  @apply flex justify-between;
}

.textWrapper {
  @apply container pr-0;
}

.leftL {
  @apply relative flex flex-col md:w-2/5 justify-center py-8 rounded-3xl md:rounded-none;
  height: fit-content;
  min-height: 350px;

  &::after {
    @apply hidden absolute inset-y-0 left-full w-72 md:block;
    content: "";
    z-index: -1;
    transform: translateX(-1px);
    clip-path: polygon(50% 0, 100% 200px, 100% 100%, 0 100%, 0 0);
    /*clip-path: url(#letter-path-l-horizontal);*/
    /*clip-path: polygon(0 70%, 20% 0, 100% 0, 100% 100%, 0 100%);*/
  }

  &::before {
    @apply hidden absolute inset-y-0 right-full md:block w-48;
    content: "";
    z-index: -1;
    /*clip-path: polygon(0 70%, 20% 0, 100% 0, 100% 100%, 0 100%);*/
  }
}

.right {
  @apply container relative md:pl-16 md:w-3/5;
}

.leftV {
  @apply relative py-8 md:w-2/5 flex flex-col justify-center rounded-3xl md:rounded-none;
  height: fit-content;
  min-height: 350px;

  &::after {
    @apply hidden absolute inset-y-0 right-full w-48 md:block;
    content: "";
    z-index: -1;
    clip-path: polygon(100% 0, 100% 100%, 50% 100%, 0 200px, 0 0);
    /*clip-path: polygon(0 70%, 20% 0, 100% 0, 100% 100%, 0 100%);*/
  }

  &::before {
    @apply hidden absolute inset-y-0 left-full w-48 md:block;
    content: "";
    z-index: -1;
    transform: scaleX(-1) translate(-50px);
    clip-path: polygon(100% 0, 100% 100%, 50% 100%, 0 200px, 0 0);
    /*clip-path: polygon(0 70%, 20% 0, 100% 0, 100% 100%, 0 100%);*/
  }
}

.leftInner {
  @apply w-full h-full;

  &::after {
    @apply hidden absolute bottom-0 left-full h-1/2 md:block;
    width: 50px;
    content: "";
    z-index: -1;
  }
}

.image {
  @apply block -mb-16 md:-mb-0 md:py-20 md:px-4;
}

.imageL {
  @apply relative block -mb-16 md:-mb-0 md:py-20 md:px-4;
}
