.listWrapper {
  > ul {
    list-style-type: none;
  }
  > ol {
    list-style-type: none;
  }
}

.listHeadline {
  @apply mb-8;
}

.listItemHeadline {
  @apply mb-4;
}

.list {
  @apply grid grid-cols-1 md:grid-cols-2 gap-24 mt-24;

  &.offset {
    & > li:nth-child(even) {
      @apply md:mt-20;
    }
  }
}

.graphic {
  @apply relative w-16 h-16 -ml-8 -mt-16;
  @apply flex items-center justify-center rounded-full;
}

.textWrapper {
  @apply relative -mt-6;
}

.text {
  > p:last-child {
    @apply mb-0;
  }
}

.listText {
  @apply lg:w-2/3 pb-24;
}

.image {
  @apply relative z-10 img-radius mb-16;
}
