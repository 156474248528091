.section {
  --hover-color: theme("colors.berry.700");
}

.headline {
  @apply mb-20 max-w-2xl;
}

.wrapper {
  @apply raster-12 lg:raster-10;
}

.accordionItem {
  @apply border-b border-black;

  &:first-child {
    @apply border-t border-black;
  }

  button {
    @apply block text-left;
  }
}

.itemHeadline {
  @apply flex items-center relative z-10 mb-0 cursor-pointer;

  [data-state="open"] & {
    & .icon {
      &:before {
        @apply scale-100 rotate-0;
        background-color: var(--hover-color);
      }
    }
  }

  & .icon {
    @apply relative p-4 md:p-7 m-0;

    &:before {
      @apply block absolute -inset-6 z-0 bg-transparent rounded-3xl transition-all duration-200 ease-out transform;
      @apply scale-0 -rotate-180;
      content: "";
    }

    & svg {
      @apply relative z-0 w-6 h-6;
      transition: transform 300ms;

      [data-state="open"] & {
        transform: rotate(45deg);
      }
    }
  }

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply relative mb-0 py-4 md:py-7 pr-4 md:pr-20;
  }
}

.content {
  @apply overflow-hidden relative z-10;

  &[hidden] {
    @apply block;
  }

  > p:last-child {
    @apply mb-0;
  }

  &[data-state="open"] {
    animation: openContent 0.3s ease-in-out;
  }
}

.innerContent {
  @apply pb-7 pt-3 pl-14 pr-4 md:px-20;

  > *:last-child {
    @apply mb-0;
  }
}

@keyframes openContent {
  0% {
    height: 0;
  }
  100% {
    height: var(--radix-accordion-content-height);
  }
}
