.listItemWrapper {
  @apply relative mb-16 last:mb-0;
  list-style-type: none;
}

.bg {
  @apply relative w-16 h-16 -ml-8;
  @apply flex items-center justify-center rounded-2xl;
}

.titleWrapper {
  @apply md:flex justify-between w-full relative z-10 -mt-6;
}

.title {
  @apply relative md:w-1/3 text-white hyphens;
}

.listItemText {
  @apply md:w-2/3 md:pl-10;
  li {
    @apply pl-8;
  }
  > p:last-child {
    @apply mb-0;
  }
}
