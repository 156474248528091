.textImageWrapper {
  @apply flex items-center relative;
}

.noMargin {
  @apply mb-0;
}

.filled {
  &.noGraphic {
    @apply py-6 md:py-10 lg:py-12 2xl:py-16;
  }

  &:last-child {
    @apply mb-0;
  }

  .contentWrapper {
    @apply pb-8 lg:pb-12 xl:pb-20 3xl:pb-24;

    &.textBlack {
      @apply text-black;
      .headlineTeaser {
        @apply text-black;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @apply text-black;
      }
    }

    &.textWhite {
      @apply text-white;
      .headlineTeaser {
        @apply text-white;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @apply text-white;
      }
    }
  }
}

.filled.imageOnly {
  .graphicWrapper {
    @apply py-8 md:py-10 lg:py-12 xl:py-20 3xl:py-24;
  }

  .contentWrapper {
    @apply pt-0 lg:pt-12 xl:pt-20 3xl:pt-24;
  }
}

.filled.letterOnly {
  @apply py-0;

  .textImage {
    @apply block;
  }
  .graphicWrapper {
    @apply absolute h-full w-full;
  }
  .letter {
    left: -65%;
    max-height: 550px;
    transform: scaleX(-1);

    @screen sm {
      left: -55%;
      right: auto;
      max-height: 550px;
    }

    @screen md {
      left: -48%;
      max-height: 610px;
    }

    @screen lg {
      left: -33%;
      max-height: 690px;
    }

    @screen xl {
      left: -25%;
      max-height: 700px;
    }

    @screen 2xl {
      left: -20%;
      max-height: 750px;
    }

    @screen 3xl {
      left: -14%;
      max-height: 800px;
    }
  }

  .contentWrapper {
    @apply py-8 lg:py-12 xl:py-16 3xl:py-20;
    @apply my-0 mx-auto w-full lg:raster-10 xl:raster-8;
  }
}

.letterOnly {
  .letterLeft {
    @apply right-1/3;

    @screen lg {
      @apply right-0;
    }
  }

  .letterRight {
    @apply left-0;
  }
}

.imageOnly {
  .graphicWrapper {
    @apply py-0;
  }

  .contentWrapper {
    @apply pt-4;

    @screen lg {
      @apply pt-8;
    }
  }
}

.letterOnly:not(.filled) {
  .contentWrapper {
    @apply pt-0;
    margin-top: -3rem;

    @screen md {
      margin-top: -4rem;
    }

    @screen lg {
      @apply pt-8;
      margin-top: 0;
    }
  }
}

.textImage {
  @apply container lg:flex items-start grid-gap relative;
}

.flexReverse {
  @apply lg:flex-row-reverse;
}

.graphicWrapper {
  @apply relative lg:w-1/2 py-12;
  min-height: 15rem;
}

.contentWrapper {
  @apply relative w-full lg:w-1/2 pt-8;
  z-index: 1;
}

.headlineTeaser {
  @apply text-white mb-6;
}

.imageWrapper {
  @apply relative img-radius;
}

.letter {
  @apply absolute inset-y-0;
  width: auto;
  height: 100.1%;
}

.letterReverse {
  transform: scaleX(-1);
}

.letterRight {
  @apply left-1/3;
}

.letterLeft {
  @apply right-1/3;
}

.text {
  > p:last-child {
    @apply mb-0;
  }
  /*@apply mb-8;*/
}

.ctaLink {
  @apply py-6;
}

.button {
  @apply mt-8;
  hyphens: none !important;
  -webkit-hyphens: none !important;
  -moz-hyphens: none !important;
  -ms-hyphens: none !important;
  word-wrap: normal;
}

@screen md {
  .graphicWrapper {
    @apply py-20;
    min-height: 20rem;
  }
}

@screen lg {
  .graphicWrapper {
    @apply py-16;
    min-height: 24rem;
  }

  .contentWrapper {
    @apply pt-24;
  }
}

@screen xl {
  .graphicWrapper {
    @apply py-20;
    min-height: 30rem;
  }
  .contentWrapper {
    @apply pt-28;
  }
}
