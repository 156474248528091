.portfolioWrapper {
  @apply relative;
}

.masonry {
  @apply flex w-full -ml-10;
}

.masonry_column {
  @apply bg-clip-padding ml-10;

  &:last-child {
    @apply mr-0;
  }

  > div {
    @apply mb-10;
  }
}

.portfolioTitle {
  @apply max-w-2xl mx-auto text-center text-3xl sm:text-5xl font-semibold;
}

.buttonWrapper {
  @apply flex items-center justify-center;
}

.referenceLink {
  @apply bg-black text-white py-4 px-6 rounded-3xl absolute left-1/2 bottom-10;
  transform: translate(-50%, 0%);
}

.grid {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    ". text text ."
    ". . . .";
}

.text {
  grid-area: text;
}
